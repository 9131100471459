
import {
  defineComponent,
  onMounted,
  ref,
  useAttrs,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import {
  RefundOverview,
  RefundLogItem,
} from "@/core/directive/interface/payment";
import { getRefundStatus } from "@/core/directive/function/paymentManagement";

export default defineComponent({
  name: "payment-management-refund-log",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs();
    const loading = ref<boolean>(false);

    const tableData = ref<Array<RefundLogItem>>([]);

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
      }
    );

    watch(
      () => attrs.baseData,
      (newValue) => {
        const base = newValue as RefundOverview;
        tableData.value.splice(0, tableData.value.length, ...base.refund_items);
      },
      { immediate: true }
    );

    const getStatusClass = computed(() => {
      return (status) => {
        return getRefundStatus(status);
      };
    });

    onMounted(() => {
      if (!attrs.loading) {
        loading.value = false;
      }
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDateTime,
      loading,
      tableData,
      getStatusClass,
    };
  },
});
